export const environment = {
  production: true,
  env: 'shrd_qae',
  serverUrl: 'https://patient-journey.zaidyn.regg.zsservices.com',
  cohortURL: 'https://cohort-builder.zaidyn.regg.zsservices.com/',
  pacHomeURL:'https://pah-home.zaidyn.regg.zsservices.com/',
  dexUrl: 'https://data-explorer.zaidyn.regg.zsservices.com',
  config: {
    idm: {
      loginUrl:
        'https://idm.dev.zsservices.com/RWB/IdentityManager/app/Web/login.aspx?ReturnUrl=',
      logoutUrl:
        'https://idm.dev.zsservices.com/RWB/IdentityManager/app/Web/logout.aspx',
      accountUrl:
        'https://idm.dev.zsservices.com/RWB/IdentityManager/app/web/MyAccount.aspx',
    },
  },
  authEnabled: false,
  authConfig: {
    issuer: 'https://federationdev.abbvie.com',
    redirectUri: 'https://patient-journey.zaidyn.dev.zsservices.com',
    clientId: 'mfa.cdldev',
    responseType: 'code',
    clientSecret:
      'qWU979R6btW0LseA76UTpZiAMtulu0ESeqK801U3rpXf4yq3GF0MtIiDGXxZlhWx',
    cookieDomain: 'zsservices.com',
  },
};
