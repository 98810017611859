import { Injectable } from '@angular/core';
import { TransactionManagerService } from './transaction-manager.service';
import { Observable } from 'rxjs';
import { Studies } from '../models/my-studies.model';
import { API_LIST } from '../constants/api-list';
import { map } from 'rxjs/operators';
import { API_FAILURE_KEY, API_SUCCESS_KEY } from '../constants/app-constants';
import { ExportModel } from '../models/export-action.model';
import {
  CloneResponseBody,
  CohortDetails,
  CohortExport,
  DataSource,
  DataSourceList,
  GlobalConceptSet,
  StudyDetailsResponse,
  ValidateTableResponse,
} from '../models/clone-action.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ActionTriggerService {
  private httpOptions: any;
  constructor(
    private transactionManager: TransactionManagerService,
    private http: HttpClient,
    private router: Router
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      withCredentials: true,
    };
  }

  getRefreshedData(studyId: string): Observable<Studies> {
    const url = 'study/' + studyId + '/refresh/';
    return this.transactionManager.post(url).pipe(
      map(
        (response: any) => {
          return response;
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getDeletedData(studyId: string): Observable<any> {
    const url = '/study/' + studyId;
    return this.transactionManager.delete(url).pipe(
      map(
        (response: any) => {
          return response;
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getExportData(studyId: string): Observable<Studies> {
    const url = 'visualizations/' + studyId + '/export';
    return this.transactionManager.get(url).pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return new ExportModel(response.data, response.status);
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getStudyDetails(studyId: string): Observable<StudyDetailsResponse> {
    const url = '/study/' + studyId;
    return this.transactionManager.get(url).pipe(
      map(
        (response: StudyDetailsResponse) => {
          if (response.status === API_SUCCESS_KEY) {
            return new StudyDetailsResponse(response);
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  editStudyDetails(studyId: string, payload: any): Observable<any> {
    const url = '/study/' + studyId;
    return this.transactionManager.put(url, payload).pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return response;
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getDataSource(): Observable<DataSource[]> {
    return this.transactionManager.get(API_LIST.dataSourceURL).pipe(
      map(
        (response: any) => {
          if (response.data.length > 0) {
            return response
            .data.map(
              (data: any) => new DataSource(data.dataSourceId, data.dataSourceName)
            );
          } else {
            return [];
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getCohort(studyId: string): Observable<any> {
    // @ts-ignore
    const url = environment.cohortURL + API_LIST.getCohortURL + studyId;
    const headers = {
        headers: new HttpHeaders({
          'charset':'UTF-8',
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        withCredentials: true,
      };
    return this.http.get(url, headers).pipe(
      map(
        (response: any) => {
          let cohortExportValue;
          return response.map((cohortDetails: any) => {
            cohortExportValue = cohortDetails.chrt_export.map(
              (cohortExportData: any) => {
                let version = cohortExportData.version;
                let globalConceptValue: GlobalConceptSet[] = [];
                let dataSourceListValue: DataSourceList[] = [];
                globalConceptValue = cohortExportData.glbl_con_set.map(
                  (globalConceptData: any) => {
                    return new GlobalConceptSet(
                      globalConceptData.con_set_id,
                      globalConceptData.con_set_nm
                    );
                  }
                );
                dataSourceListValue = cohortExportData.data_src_list.map(
                  (dataSourceList: any) => {
                    return new DataSourceList(
                      dataSourceList.s3bucket,
                      dataSourceList.data_src_id,
                      dataSourceList.data_src_displ_nm,
                      dataSourceList.cohort_export_s3_path
                    );
                  }
                );
                return new CohortExport(
                  globalConceptValue,
                  dataSourceListValue,
                  version
                );
              }
            );
            return new CohortDetails(
              cohortDetails.chrt_id,
              cohortDetails.chrt_nm,
              cohortDetails.chrt_desc,
              cohortExportValue
            );
          });
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  getCloneStudy(payload: any, studyId: string) {
    const url = 'study/' + studyId + '/clone/';
    return this.transactionManager.post(url, payload).pipe(
      map(
        (response: any) => {
          return new CloneResponseBody(response.status, response.message);
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  validateStudyTitle(payload: any) {
    return this.transactionManager.post(API_LIST.checkStudyTitle, payload).pipe(
      map(
        (response: any) => {
          return response;
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  validateS3Location(payload: any) {
    return this.transactionManager.post(API_LIST.validateTable, payload).pipe(
      map(
        (response: any) => {
          if (response.status === API_SUCCESS_KEY) {
            return new ValidateTableResponse(
              response.status,
              response.message,
              response?.data_sufficiency,
              response?.tables_missing,
              response?.tables_present
            );
          } else {
            return this.errorHandler(response);
          }
        },
        (err: any) => {
          return this.errorHandler(err);
        }
      )
    );
  }

  errorHandler(e: any = ''): any {
    if (e.error == 'Study access issue') {
      this.router.navigate(['studies/error/access_issue']);
    }
    return { status: API_FAILURE_KEY };
  }
}
